<template>
    <div>
        <v-row no-gutters dense>
            <v-col cols="12" sm="6" class="pa-0">
                <p class="my-heading">Dashboard</p>
            </v-col>
            <v-col cols="12" sm="6" style="z-index: 1">
                <v-col class="d-flex justify-end ml-auto mr-3 align-center">
                    <div>
                        <div class="d-flex justify-end">
                            <div class="ml-auto heading-rf15">
                                Filter by month & year
                            </div>
                            <div
                                v-on:click="
                                    filter_data.is_show = !filter_data.is_show
                                "
                                class="ml-4 my-pointer d-flex flex-column"
                            >
                                <img
                                    :src="$store.state.icons.icons['calendar']"
                                    style="height: 20px"
                                />
                            </div>
                        </div>

                        <div v-if="filter_data.is_show" class="pt-2">
                            <app-date-time
                                v-model="filter_data"
                            ></app-date-time>
                        </div>
                    </div>
                </v-col>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Transaction']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ $options.filters.currency(dashboard.transaction) }}
                        <p class="heading-sf15">Total Transaction</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Payment']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ $options.filters.currency(dashboard.payment) }}
                        <p class="heading-sf15">Total Payment</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Sales-Tax']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ $options.filters.currency(dashboard.sales_tax) }}
                        <p class="heading-sf15">Sales Tax</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Total-profit']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ $options.filters.currency(dashboard.profit) }}
                        <p class="heading-sf15">Total Profit</p>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Contractors']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.contractors }}
                        <p class="heading-sf15">Contractors</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Distributors']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.distributors }}
                        <p class="heading-sf15">Distributors</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pr-4 pl-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img
                            :src="$store.state.icons.icons['Authorize-Users']"
                        />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.authorize_users }}
                        <p class="heading-sf15">Authorize Users</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pl-4 pr-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Crew-members']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.crews }}
                        <p class="heading-sf15">Crew Members</p>
                    </div>
                </div>
            </v-col>

            <!-- <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-1">
        <v-row no-gutters dense> -->
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pl-4 pr-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img :src="$store.state.icons.icons['Projects']" />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.projects }}
                        <p class="heading-sf15">Projects</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pl-4 pr-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img
                            class="list-icon"
                            :src="$store.state.icons.icons['Lists@3x']"
                        />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.lists }}
                        <p class="heading-sf15">Lists</p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pl-4 pr-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img
                            class="bid-icon"
                            :src="$store.state.icons.icons['Cube']"
                        />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.bids }}
                        <p class="heading-sf15">Bids</p>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" md="4" sm="6" lg="3" xl="2" class="pb-8 pl-4 pr-4">
                <div class="my-card-1 d-flex">
                    <div class="small-card-1 d-flex align-center justify-start">
                        <img
                            class="deliveryvan-icon"
                            :src="$store.state.icons.icons['DeliveryVan']"
                        />
                    </div>
                    <div
                        class="
                            small-card-2
                            d-flex
                            flex-column
                            align-center
                            justify-center
                        "
                    >
                        {{ dashboard.deliveries }}
                        <p class="heading-sf15">Deliveries</p>
                    </div>
                </div>
            </v-col>
            <!-- </v-row> -->
            <!-- </v-col> -->

            <v-col cols="12" class="pb-8">
                <app-bar-chart></app-bar-chart>
            </v-col>

            <v-col cols="12" class="pb-8">
                <app-delivery-chart></app-delivery-chart>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filter_data: {
                is_show: false,
                filter_type:
                    this.$store.state.dashboard.dashboar_data.filter_type,
                old_filter_type:
                    this.$store.state.dashboard.dashboar_data.filter_type,
                filter_date: this.$store.state.dashboard.dashboar_data.date,
                old_filter_date: this.$store.state.dashboard.dashboar_data.date,
            },
        };
    },
    components: {
        AppBarChart: () => import("./BarChart"),
        AppDeliveryChart: () => import("./DeliveryChart"),
        AppDateTime: () => import("../../mycomponents/DateTime"),
    },

    async mounted() {
        this.$store.dispatch("contList");
        this.$store.dispatch("distList");
        this.$store.dispatch("dashboardInfo", {
            date: this.filter_data.filter_date,
            type: this.filter_data.filter_type,
        });
    },

    computed: {
        dashboard() {
            return this.$store.getters.getDashboardInfo;
        },
    },

    watch: {
        "filter_data.is_show"() {
            if (
                (this.filter_data.is_show === false &&
                    this.filter_data.old_filter_date !==
                        this.filter_data.filter_date) ||
                this.filter_data.old_filter_type !==
                    this.filter_data.filter_type
            ) {
                this.filter_data.old_filter_type = this.filter_data.filter_type;
                this.filter_data.old_filter_date = this.filter_data.filter_date;

                this.$store.dispatch("dashboardInfo", {
                    date: this.filter_data.filter_date,
                    type: this.filter_data.filter_type,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .my-card-1 {
        background: #ffffff;
        color: #212121;
        box-shadow: 0 16px 32px 0 rgba(133, 156, 171, 0.15) !important;
        border-radius: 8px !important;
        min-height: 80px !important;
    }
    .small-card-1 {
        padding: 8px 0px 8px 26px;
        width: 72px;
        font-family: Inter-SemiBold;
        font-size: 40px;

        img {
            height: 40px;
            filter: invert(66%) sepia(13%) saturate(418%) hue-rotate(176deg)
                brightness(98%) contrast(85%);
        }
    }

    .small-card-2 {
        padding: 8px 16px;
        width: 100%;
        font-family: Inter-SemiBold;
        font-size: 20px;
        color: #212121;

        .heading-sf15 {
            color: #7587a3;
        }
    }
</style>